.demo-image-preview {
  position: relative;
  text-align: center;
}

.demo-image-preview > img {
  width: 768px;
}

.loader {
	position: absolute;
	top:30%;
	left:2%;
}
.loader > img {
	width:50%;
	height: 50%;
}
 @media screen and (orientation:landscape) { 
 	.loader {
	left:20%;
}

 	}
@media (max-width: 768px) {
  .demo-image-preview > img {
    width: 100%;
  }
}

/* fullscreen enable by props */
.demo-image-preview-fullscreen > img {
  width: 100vw;
  height: 100vh;
}

