.img-style {
    width:100%;
    height: 100%;
}
.upload-button {
    background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
    /* width:100%;
    height: 100%;
    margin-left: 50px;
    margin-top: 180px; */
}